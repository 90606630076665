@charset "UTF-8";
:root {
  --roig: #ff5451;
  --negre: #2f2f2f;
  --gris: #717171;
  --clar: #f6f8fb;
  --beige: #f5f5f5;
  --blanc: #fff; }

body {
  background-color: var(--blanc);
  color: #363636;
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-weight: 400; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Montserrat; }

a {
  color: var(--roig); }

a:hover {
  color: var(--negre); }

.dotted {
  border-color: var(--blanc);
  border-style: dashed;
  margin-bottom: 2rem; }

/* Menu principal
------------------------------------------------------------ */
nav ul.navbar-nav .nav-item .nav-link {
  font-size: 1rem;
  font-weight: 700; }

nav ul.navbar-nav .nav-item .nav-link {
  padding-right: 0.8rem;
  padding-left: 0.8rem; }

/* Headers
------------------------------------------------------------ */
.header-paginas {
  padding: 150px 0;
  position: relative;
  text-align: center;
  background-size: cover !important;
  background-repeat: no-repeat; }

.header-paginas .overlay {
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0; }

.header-paginas h1 {
  color: #fff; }

.header-paginas h4 {
  font-weight: 400;
  color: #fff; }

.logo {
  width: 170px !important;
  height: auto; }

@media (max-width: 767px) {
  .logo {
    width: 120px !important;
    height: auto; }
  .header-paginas {
    padding: 30px 0; }
  .header-paginas h1 {
    font-size: 22px;
    font-weight: bold; }
  .header-paginas h4 {
    font-size: 14px; }
  .header-paginas .overlay {
    background-color: rgba(0, 0, 0, 0.8); } }

/* Carrousel
------------------------------------------------------------ */
.carousel-item {
  height: 600px; }

.carousel-item h4 {
  font-size: 3rem;
  color: #fff;
  font-weight: 700;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1); }

.carousel-item p {
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  font-size: 18px; }

.carousel-item .carousel-caption {
  /*background-color: rgba(0, 0, 0, 0.4);*/
  top: 50%;
  transform: translateY(-50%);
  bottom: initial; }

.carousel-item .overlay {
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute !important;
  top: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%; }

/* Paddings, margenes
------------------------------------------------------------ */
.padding-min {
  padding: 20px 0px; }

.padding {
  padding: 60px 0px; }
  @media screen and (max-width: 767px) {
    .padding {
      padding: 45px 0; } }

.padding-max {
  padding: 110px 0px; }

.mbottom {
  margin-bottom: 50px; }

/* Colores
------------------------------------------------------------ */
.bg-beige {
  background-color: var(--beige); }

.bg-tips {
  background: url("https://staging.tu-auto.es/wp-content/uploads/2018/05/bg-road-full.jpg") center bottom;
  position: relative;
  background-size: cover; }

.bg-tips .overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0; }

.bg-negre {
  background-color: var(--negre); }

.bg-roig {
  background-color: var(--roig); }

.blanc {
  color: #fff !important; }

.top {
  border-top: 8px solid var(--roig);
  z-index: 20000; }

/* Títulos
------------------------------------------------------------ */
.titulo {
  color: var(--gris);
  text-transform: uppercase;
  font-size: 20px;
  font-family: Montserrat;
  letter-spacing: 3px;
  font-weight: 600; }

.titulo-rojo {
  color: var(--roig);
  text-transform: uppercase;
  font-size: 20px;
  font-family: Montserrat;
  letter-spacing: 3px;
  font-weight: 600; }

.titulo-max {
  color: var(--roig);
  font-size: 2.5rem;
  font-weight: 700; }

.titulo-alt {
  color: var(--blanc);
  font-size: 18px;
  font-family: Montserrat;
  letter-spacing: 3px;
  font-weight: 400; }

@media (max-width: 767px) {
  .titulo {
    font-size: 15px !important; }
  .titulo-max {
    font-size: 1.5rem !important; } }

/* Cards
------------------------------------------------------------ */
.card-auto {
  background-color: #fff !important;
  box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 50px; }

.card-auto h3.card-title {
  font-size: 25px !important;
  font-weight: bold;
  margin-bottom: 2px;
  text-transform: lowercase; }

.card-auto h3.card-title:first-letter {
  text-transform: capitalize; }

.card-auto h4 {
  font-size: 16px !important;
  margin-bottom: 3px; }

.card-auto p {
  color: var(--gris); }

.card-auto .price {
  font-size: 25px !important;
  color: var(--roig); }

.card-auto a.icon {
  position: relative; }

.card-auto a.icon h5 {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 10000;
  letter-spacing: 1px;
  font-weight: 600; }

/* HOME: Tips Página
------------------------------------------------------------ */
.tip {
  text-align: text-center; }

.tip h2,
.tip p {
  color: var(--blanc); }

.tip h2 {
  font-size: 22px; }

.tip p {
  color: var(--clar); }

.tip a {
  color: var(--blanc); }

/* VEHICULO: Cabecera stiky de coches en single-vehiculo.php
------------------------------------------------------------ */
.header-coche h1 {
  font-size: 1.5rem;
  font-weight: 700; }

.header-coche h1 span {
  font-size: 1.5rem;
  font-weight: 300;
  color: var(--gris); }

.header-coche {
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 14px 25px -7px rgba(0, 0, 0, 0.16); }

.header-coche .extra-info span {
  margin-right: 10px; }

/* VEHICULO:Visualizador coche
------------------------------------------------------------ */
.slider-for img {
  width: 100%;
  height: auto;
  vertical-align: middle; }

.slider-nav img {
  width: auto;
  height: 80px; }

.slider-for .item {
  width: 100%;
  height: 750px;
  position: relative; }

.slider-for img {
  position: absolute;
  top: -100%;
  left: 0;
  right: 0;
  bottom: -100%;
  margin: auto; }

.visualizador {
  position: relative; }

.slider-for .slick-prev {
  z-index: 1000;
  left: 0;
  background-image: url("../img/gallery-big-arrow-prev.png") !important; }

.slider-for .slick-next {
  z-index: 1000;
  right: 0;
  background-image: url("../img/gallery-big-arrow-next.png") !important; }

/* .slick-next {
	right: -25px !important; } */
.slick-prev::before,
.slick-next::before {
  display: none; }

.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 15px;
  height: 15px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  outline: none;
  background: transparent;
  background-image: none !important; }

.slider-for .slick-arrow {
  width: 70px;
  height: 100px;
  margin-top: -50px;
  -webkit-transform: none;
  transform: none; }

.galeria-coche {
  background-color: rgba(0, 0, 0, 0.1); }

.galeria-coche {
  padding: 15px 0 15px 0 !important;
  width: 100%;
  position: absolute;
  bottom: 0px;
  min-height: 100px;
  overflow: hidden;
  z-index: 1000; }

.galeria-coche img {
  border: 1px solid #fff; }

/* VEHICULO: Extras vehículo y caracteristicas
------------------------------------------------------------ */
.extras {
  padding: 0px 10px; }

.extras img {
  width: 40px;
  height: auto;
  margin-bottom: 20px; }

.extras h6 {
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 0px;
  padding-bottom: 0px;
  line-height: 0.3rem; }

.extras span {
  color: #828282;
  font-size: 12px; }

@media (max-width: 575px) {
  .g-extras {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem; } }

.caracteristicas .caracteristica {
  margin-bottom: 30px; }

.caracteristicas .caracteristica h6 {
  font-weight: 400;
  margin-bottom: 5px; }

.caracteristicas .caracteristica p {
  text-transform: lowercase; }

.caracteristicas .caracteristica p:first-letter {
  text-transform: capitalize; }

/* Galeria responsive
------------------------------------------------------------ */
.galeria {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2px;
  margin-top: 2px; }

.galeria a img {
  width: 100%;
  height: auto; }

@media (min-width: 768px) {
  .galeria {
    display: none; } }

/* VEHICULO: Mayusuculas transform
------------------------------------------------------------ */
.video-container {
  border-radius: 8px;
  overflow: hidden;
  display: block;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); }

/* VEHICULO: Mayusuculas transform
------------------------------------------------------------ */
span.up {
  text-transform: lowercase; }

span.up:first-letter {
  text-transform: capitalize !important; }

/* Barra lateral precio coche
------------------------------------------------------------ */
.list-tips h5 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0px; }

.list-tips img {
  width: 30px;
  height: auto; }

.card-precio {
  margin-top: -250px;
  z-index: 1001;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15); }

.card-precio big {
  display: block;
  color: var(--roig);
  font-size: 2.4rem;
  font-family: Montserrat; }

.mapa iframe {
  border: 5px solid var(--blanc) !important; }

/* SERVICIOS
------------------------------------------------------------ */
ul.servicios li img {
  width: 70px;
  height: auto; }

ul.servicios li p {
  color: var(--gris); }

ul.servicios li h5 {
  color: var(--negre); }

footer ul.nav a {
  color: var(--blanc); }

@media (max-width: 575px) {
  ul.servicios li img {
    width: 40px;
    height: auto; }
  ul.servicios li h5 {
    font-weight: bold; } }

/* WIDGETS
------------------------------------------------------------ */
.widget {
  margin-bottom: 35px; }

.widget h4 {
  text-transform: uppercase;
  font-size: 18px;
  color: var(--roig);
  font-weight: 700;
  letter-spacing: 2px; }

.widget .attachment-medium {
  border-radius: 4px; }

.widget ul.menu li {
  list-style: none; }

.widget ul.menu li a {
  color: var(--gris); }

.widget ul.menu li a:hover {
  color: var(--roig); }

/* BOOTSTRAP CLASES MODIFICADAS
------------------------------------------------------------ */
.btn-block {
  border-radius: 60px;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 2px;
  padding: 0.7rem 1rem; }

/* Formulario
------------------------------------------------------------ */
.wpcf7-form {
  padding: 50px 40px;
  background-color: var(--beige);
  border-radius: 3px;
  border: var(--clar) 1px solid; }

@media (max-width: 575px) {
  .wpcf7-form {
    padding: 1rem; } }

/* =====================================================
/  =====================================================
/  Responsive media querys
/  =====================================================
/  ===================================================== */
@media (min-width: 7676px) and (max-width: 992px) {
  .slider-for .item {
    height: 600px !important; } }

@media (min-width: 481px) and (max-width: 767px) {
  .slider-for .item {
    height: 400px !important; } }

@media (max-width: 480px) {
  .header-coche h1 {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 2px; }
  .header-coche h1 span {
    font-size: 1rem;
    font-weight: 300;
    color: var(--gris); }
  .header-coche {
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 14px 25px -7px rgba(0, 0, 0, 0.16); }
  .header-coche .extra-info span {
    margin-right: 5px; }
  .none {
    display: none; }
  .titulo {
    color: var(--gris);
    text-transform: uppercase;
    font-size: 1rem;
    font-family: Montserrat;
    letter-spacing: 3px;
    font-weight: 600; }
  .titulo-rojo {
    color: var(--roig);
    text-transform: uppercase;
    font-size: 20px;
    font-family: Montserrat;
    letter-spacing: 1px;
    font-weight: 600; }
  .titulo-max {
    color: var(--roig);
    font-size: 2rem;
    font-weight: 700; }
  .titulo-alt {
    color: var(--blanc);
    font-size: 18px;
    font-family: Montserrat;
    letter-spacing: 3px;
    font-weight: 400; }
  .slider-for .item {
    height: 300px !important; }
  .slider-for .slick-arrow {
    width: 40px;
    height: 60px;
    background-size: cover; }
  .galeria-coche {
    display: none; }
  .card-precio {
    margin-top: 0px; } }

/* Navs ---------------------------- */
.nav-terms {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  justify-content: center;
  flex-wrap: wrap; }
  @media screen and (max-width: 767px) {
    .nav-terms__item {
      flex: 1 1 100%;
      gap: 0.5rem; } }
  .nav-terms__item a {
    padding: 0.6rem 1.2rem;
    background: var(--blanc);
    color: var(--gris);
    border-radius: 50px;
    display: inline-flex; }
    @media screen and (max-width: 767px) {
      .nav-terms__item a {
        width: 100%;
        justify-content: center; } }
    .nav-terms__item a:hover {
      text-decoration: none; }
  .nav-terms__item--active a {
    background-color: var(--roig);
    color: var(--white); }
  .nav-terms__item--all {
    font-weight: bold; }

.page-template-page-coches .nav-terms__item--all a {
  background-color: var(--roig);
  color: var(--white); }
